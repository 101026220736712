/**
 * login模块接口列表
 */
import axios from '@/service/api/request' // 导入http中创建的axios实例
const api = {
    // // get请求不带参数
    // getAlldata() {
    //   return axios.get(`/lsx/getAll`)
    // },
    // get请求带参数
    // articleDetail(params) {
    //   return axios.get('/api/', {
    //     params: params
    //   })
    // },
    // post提交
    // login(params) {
    //   return axios.post('/api/shop', params)
    // }
    // 其他接口…………
    // 登录接口.............................................................................
    add(params) {
        console.log(111111111111,params)
        return axios.post('/media/asset/add',params)
    },

};

export default api

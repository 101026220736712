<template>
  <div>
    <div>
      <div class="class_nav">
        <span>当前位置：</span> <span>作品管理</span> > <span>{{ title }}</span>
      </div>
      <div class="course-nav dis-flex">
        <div class="course-nav-item dis-flex">
          <h3>班级：</h3>
          <div>
            <el-select
              @change="change"
              v-model="class_value"
              placeholder="请选择班级"
            >
              <el-option
                v-for="item in options"
                :key="item.class_id"
                :label="item.class_name"
                :value="item.class_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div v-if="myworks" class="course-nav-item dis-flex">
          <h3>课程：</h3>
          <div>
            <el-select v-model="class_valuess" placeholder="请选择课程">
              <el-option
                v-for="item in optionsss"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="upworks">
        <h3>上传相册</h3>
        <div class="margin-auto-20">
          <div style="width: 500px">
            <el-upload
              class="upload-demos"
              style="width: 500px"
              multiple
              ref="upload"
              list-type="picture"
              action="https://up-z2.qiniup.com"
              :headers="headers"
              :data="formData"
              accept="bmg,.png,.jpg,.jpeg"
              :before-upload="beforeUpload"
              :on-remove="remove"
              :limit="20"
            >
              <el-button size="small" type="primary" @click="qn"
                >添加图片</el-button
              >
            </el-upload>
          </div>
        </div>
      </div>
    </div>
    <div @click="upFiles" class="upclick cursor-pointer">上传</div>
  </div>
</template>

<script>
import api from "@/service/api/api";
import jbapi from "@/service/api/jbapi";
import axios from "axios";
var loading;
export default {
  name: "upphoto",
  data() {
    return {
      works: [{ id: "", img: "", name: "2021油画公开课01", text: "" }],
      class_value: "",
      options: [],
      title: "",
      class_values: "",
      optionss: [],
      class_valuess: "",
      optionsss: [],
      // 上传
      studentfile: [],
      multiple: true,
      headers: {
        token: "",
      },
      formData: {
        region: "SCN",
        token: this.uppictoken,
      },
      album_uid: "",
      myworks: false,
      stutent: false,
      // 上传七牛云后属猪
      images: [],
      // 本地路径数组
      filesList: [],
      // 党建逼返回的图片数组
      nowpic: [],
      album_id: "",
    };
  },
  created() {
    this.title = this.$route.query.title;
    this.album_id = this.$route.query.album_id;

    this.classseletinit();
  },
  methods: {
    // 获取上传七牛云参数
    qn() {
      let params = {
        class_id: this.class_value,
        course_id: this.class_valuess,
      };
      api.qn(params).then((res) => {
        if (res.errcode === 0) {
          this.headers.token = res.data.uppictoken;
          this.formData.region = res.data.region;
          this.formData.token = res.data.uppictoken;
          this.album_uid = res.data.album_uid;
          let xiaomeitoken = res.data.xiaomeitoken;
          localStorage.setItem("token", xiaomeitoken);
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning",
          });
        }
      });
    },
    // 班级选择初始化接口
    classseletinit() {
      api.getclasscourseall().then((res) => {
        if (res.errcode === 0) {
          this.options = res.data;
          if (this.options.length === 1) {
            this.class_value = this.options[0].class_id;
            this.class_valuess = this.options[0].course[0].id;
            this.optionss = this.options[0].classmember;
            this.optionsss = this.options[0].course;
            this.myworks = true;
            this.stutent = true;
          } else {
            this.class_value = "";
            this.optionss = [];
            this.optionsss = [];
            this.myworks = false;
            this.stutent = false;
          }
          // console.log(this.class_value,  this.class_valuess)
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning",
          });
        }
      });
    },
    change() {
      console.log(this.class_value);
      this.options.forEach((item, index) => {
        if (this.class_value === item.class_id) {
          this.optionss = item.classmember;
          this.optionsss = item.course;
          this.myworks = true;
          this.stutent = true;
          console.log(this.optionss, this.optionsss);
        }
      });
    },
    // 上传前判断
    beforeUpload(file) {
      loading = this.$loading({
        lock: true,
        text: "处理中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.filesList.push(file.name);
      let imageidx = this.images.length;
      this.images.push([]);
      return new Promise((resolve, reject) => {
        var list = [];
        /* 512的 */
        this.$u
          .compressImage(file, {
            width: 512,
          })
          .then((res) => {
            list.push(res);
            /* 1000的 */
            this.$u
              .compressImage(file, {
                width: 1000,
              })
              .then((result) => {
                list.push(result);
                let files = list.map((e, index) => {
                  return this.$u.dataURLtoFile(
                    e,
                    index === 0 ? "压缩图" : "高清图"
                  );
                });
                files.push(file);
                // console.log(files);
                let arr = [];
                files.forEach((e, index) => {
                  this.uploadFiles(e, (me) => {
                    if (this.images[imageidx]) {
                      this.images[imageidx][index] = me;
                      // console.log(arr.length, index)
                    } else {
                      this.images[imageidx] = [me];
                    }
                  });
                });
              });
          });
      });
    },

    // 删除缩略图
    remove(f) {
      this.images.forEach((item, index) => {
        console.log(item[0].name, f.name);
        if (item[2].name === f.name) {
          this.images.splice(item, 1);
        }
      });
      console.log(this.images, 77777777);
    },

    // 覆盖默认的上传行为，自定义上传的实现
    uploadFiles(e, call) {
      const axiosInstance = axios.create({ withCredentials: false }); //withCredentials 禁止携带cookie，带cookie在七牛上有可能出现跨域问题
      // this.$refs.upload.submit();
      let data = new FormData();
      data.append("token", this.formData.token);
      data.append("file", e);
      axiosInstance({
        method: "POST",
        url: "https://up-z2.qiniup.com", //上传地址
        data: data,
        timeout: 30000, //超时时间，因为图片上传有可能需要很久
        onUploadProgress: (progressEvent) => {
          //imgLoadPercent 是上传进度，可以用来添加进度条
          let imgLoadPercent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
        .then((data) => {
          // console.log(data.data)
          call(data.data);
          setTimeout(() => {
            loading.close();
          }, 2000);
          // this.images.push(data.data)
          // console.log('wwwwww',this.images)

          //上传成功...
        })
        .catch(function (err) {
          console.log(err);
          //上传失败
        });
    },
    // 上传后端
    upFiles() {
      this.nowpic=[]
      console.log(this.images, "十大歌手水电费瑞特");
      // 所以图片
      let newimg = [];
      // 压缩图
      let thumbnail = [];
      // 高清图
      let definition = [];
      // 原图
      let original = [];
      this.images.forEach((value, index, arry) => {
        // console.log(value)
        value.forEach((j, index, arry) => {
          // console.log(j)
          newimg.push(j);
        });
        // console.log(newimg)
      });
      newimg.forEach((item, index) => {
        if (item.name === "压缩图") {
          thumbnail.push(item);
        } else if (item.name === "高清图") {
          definition.push(item);
        } else {
          original.push(item);
        }
      });

      let pic = this.images.map((v, i) => {
        let source = v.filter((value) => {
          if (value.name != "压缩图" && value.name != "高清图") {
            return true;
          }
        });

        let so = source[0];

        let hd = v.filter((value) => {
          if (value.name == "高清图") {
            return true;
          }
        });
        hd = hd[0];

        let sd = v.filter((value) => {
          if (value.name == "压缩图") {
            return true;
          }
        });
        sd = sd[0];

        return {
          key: so.key,
          format: so.type,
          md5: so.hash,
          size: so.size,
          width: so.img.width,
          height: so.img.height,
          group: this.album_uid,
          snapshot: hd.key,
          small: sd.key,
          path: this.filesList[i],
          type: 1,
        };
      });

      // console.log(111,thumbnail)
      // console.log(222,definition)
      // console.log(333,original)
      // 原图图
      // var thumbs = [];
      var thumbs = original.map((value, index, arry) => {
        return {
          key: value.key,
          format: value.type,
          md5: value.hash,
          size: value.size,
          width: value.img.width,
          height: value.img.height,
          group: this.album_uid,
          type: 1,
        };
      });
      // 本地path/name
      // let newfilesList = []
      let newfilesList = this.filesList.map((value, index, arry) => {
        return {
          path: value,
        };
        // newfilesList.push({
        //   path: value
        // })
      });
      console.log(newfilesList, "path");
      // 缩略图
      // var small_url = [];
      var small_url = thumbnail.map((value, index, arry) => {
        return {
          small: value.key,
        };
        // small_url.push({
        //   'small': value.key,

        // })
      });
      // 高清图
      // var snapshot = [];
      var snapshot = definition.map((value, index, arry) => {
        return {
          snapshot: value.key,
        };
        // snapshot.push({
        //   'snapshot': value.key,

        // })
      });
      // console.log(111,thumbs)
      // console.log(222,small_url)
      // console.log(333,snapshot)

      // var pic = snapshot.map((item, index) => {
      //   return {
      //     ...item,
      //     ...small_url[index],
      //     ...newfilesList[index],
      //     ...thumbs[index],
      //   };
      // });
      console.log(pic, 666666);
      //  this.$message({
      //     message: res.errmsg,
      //     duration: 2000,
      //     type: "warning",
      //   });
      // 上传党建逼
      for (let index = 0; index < pic.length; index++) {
        jbapi.add(pic[index]).then((res) => {
          this.nowpic.push(res.info);
          console.log(333, this.nowpic);

          if (this.nowpic.length == this.images.length) {
            this.$refs.upload.clearFiles();
            this.images = [];
            this.filesList = [];


            let lipic = this.nowpic.map((value, index, arry) => {
              console.log(value);
              return {
                small_url: value.small,
                snapshot: value.snapshot,
                img_url: value.url,
                thumbs: value.url,
                uid: value.uid,
              };
            });
            
            // // 上传后台
            let params = {
              class_id: this.class_value,
              course_id: this.class_valuess,
              album_id: this.album_id,
              pic: JSON.stringify(lipic),
            };
            api.upphoto(params).then((res) => {
              if (res.errcode === 0) {

                this.$message({
                  message: res.errmsg,
                  duration: 2000,
                  type: "warning",
                });
              } else {
                this.$message({
                  message: res.errmsg,
                  duration: 2000,
                  type: "warning",
                });
              }
            });
          }
        });
      }
      // pic.forEach((item, index) => {

      // })
    },
  },
};
</script>

<style scoped>
.class_nav {
  width: 1200px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  color: #ff8e42;
}

.class_nav span:first-child {
  color: #999999;
}

.course-nav {
  width: 1200px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  /*background-color: #4A80F7;*/
}

.course-nav-item {
  color: #999999;
  font-size: 16px;
  margin-right: 20px;
}
.upworks {
  width: 1200px;
  margin: 0 auto;
}
.upworks > h3 {
  width: 80px;
  height: 50px;
  line-height: 60px;
  color: #333333;
  font-size: 20px;
  font-weight: 400;
  border-bottom: 1px solid #ff8e42;
}

.upclick {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  background-color: #ff8e42;
  margin: 30px auto;
  border-radius: 4px;
}

.avatarImg {
  width: 100%;
  height: 100%;
}

.divBox {
  display: flex;
  flex-wrap: wrap;
}

.divBox-img {
  margin: 15px 0 15px 20px;
  position: relative;
}

.imgSty {
  margin-left: 3px;
}

.delIcon {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 25px;
  background-color: #fb3838;
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}
</style>